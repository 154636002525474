/*eslint-disable*/
import React, {useState} from "react";
// react components for routing our app without refresh
import {Link, useHistory} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import {Apps, Loupe, LoupeSharp, Person} from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import config from "../../config/config.json";
import axios from "axios";
import toast, {Toaster} from "react-hot-toast";
import Search from "@material-ui/icons/Search";
import ladderOn from "assets/img/ladder_on.png";
import ladderOff from "assets/img/ladder_off.png";
import hardcoreOn from "assets/img/hardcore_on.png";
import hardcoreOff from "assets/img/hardcore_off.png";
import chestImg from "assets/img/d2rchest.png";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    const classes = useStyles();
    const logoutFunc = () => {
        localStorage.clear()
        // history.push("/login")
        window.location.reload(true);
    };

    let sessionToken = localStorage.getItem("session_token")
    let ladderDefault = localStorage.getItem("ladder")
    let hardcoreDefault = localStorage.getItem("hardcore")
    let nodeDefault = localStorage.getItem("nodename")
    const [userData, setUserData] = useState({});
    const [loadRole, setLoadRole] = useState(true);

    let loggedIn = false
    if (sessionToken) {
        loggedIn = true
    }

    let laddString =  "Ladder"
    let ladd =  parseInt(ladderDefault)
    let coreString =  "Softcore"
    let core = parseInt(hardcoreDefault)
    if (ladd === 0) {
        laddString = "Non-Ladder"
    }

    if (core === 1) {
        coreString = "Hardcore"
    }

    if (!nodeDefault) {
        nodeDefault = "All"
    }

    const [disablePlatform, setDisablePlatform] = useState(false);
    const [currentContext, setCurrentContext] = useState(coreString+" "+laddString);
    const [availableNodes, setAvailableNodes] = useState([nodeDefault]);
    const [currentNode, setCurrentNode] = useState(nodeDefault);
    const [isLoggedIn, setIsLoggedIn] = useState(loggedIn);
    const [isStaff, setIsStaff] = useState(false);
    const [isNodeOwner, setIsNodeOwner] = useState(false);

    async function getProfile() {
        await axios.get(config.backend_base_url + 'profile', {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                setUserData(response.data)
            }
        }).catch(error => {
            console.log("Error ========>", error);
        })
    }

    let currentContextFunc = function (event, context) {
        event.preventDefault()
        setCurrentContext(context)
        if (context === "Softcore Ladder") {
            sendContextChange(0, 1)
        }
        if (context === "Softcore Non-Ladder") {
            sendContextChange(0, 0)
        }
        if (context === "Hardcore Ladder") {
            sendContextChange(1, 1)
        }
        if (context === "Hardcore Non-Ladder") {
            sendContextChange(1, 0)
        }
    }
    let sendContextChange = function (hardcoreValue, ladderValue) {
        axios.post(config.backend_base_url + "user/update/contexts", {
            "hardcore": hardcoreValue,
            "ladder": ladderValue,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            console.log("Success ========>", response.data);
            localStorage.setItem("hardcore", hardcoreValue)
            localStorage.setItem("ladder", ladderValue)
            window.location.reload(true);
        }).catch(error => {
            console.log("Error ========>", error);
            window.location.reload(true);
        })
    }
    let currentNodeFunc = function (event, newnodename) {
        event.preventDefault()
        sendNodeChange(newnodename)
    }
    let sendNodeChange = function (newnodename) {
        newnodename = newnodename.split("(")[0]
        axios.post(config.backend_base_url + "user/update/node", {
            "current_node": newnodename,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            console.log("Success ========>", response.data);
            localStorage.setItem("nodename", newnodename)
            window.location.reload(true);
        }).catch(error => {
            console.log("Error ========>", error);
            window.location.reload(true);
        })
    }

    function getUserType() {
        if (!sessionToken) {
            setIsLoggedIn(false)
        } else {
            let url = config.backend_base_url + 'user/type'
            axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
                if (response.data != null) {
                    if (response.data["deploying"] === 1) {
                        console.log(response.data["deploying"])
                        toast.loading("Deployment in process. Please do not initiate actions until this Notification disappeared.")
                        setDisablePlatform(true)
                    }
                    if (response.data["node_name"]) {
                        localStorage.setItem("nodename", response.data["node_name"])
                        setCurrentNode(response.data["node_name"])
                    }
                        setIsStaff(response.data["is_staff"] === 1)
                        setIsNodeOwner(response.data["is_node_owner"] === 1)
                    if (response.data["allowed_nodes"] && response.data["allowed_nodes"].length > 0) {
                        setAvailableNodes(response.data["allowed_nodes"])
                    }
                    let laddString =  "Ladder"
                    let coreString =  "Softcore"
                    if (response.data["ladder"] === 1) {
                        localStorage.setItem("ladder", 1)
                        laddString =  "Ladder"
                    } else {
                        localStorage.setItem("ladder", 0)
                        laddString =  "Non-Ladder"
                    }
                    if (response.data["hardcore"] === 1) {
                        localStorage.setItem("hardcore", 1)
                        coreString =  "Hardcore"
                    } else {
                        localStorage.setItem("hardcore", 0)
                        coreString =  "Softcore"
                    }
                    setCurrentContext(coreString + " " + laddString)
                }
            }).catch(error => {
                console.log("Error ========>", error);
                localStorage.clear()
            })
        }
    }

    const replaceCanMuleWithImage = (text) => {
        return text.split('CAN_MULE').map((part, index) => (
            <React.Fragment key={index}>
                {part}
                {index < text.split('CAN_MULE').length - 1 && (
                    <img
                        style={{ width: '16px', verticalAlign: 'middle' }}
                        src={chestImg}
                        alt="chest"
                    />
                )}
            </React.Fragment>
        ));
    };

    if (loadRole) {
        getUserType()
        getProfile()
        setLoadRole(false)
    }

    return (
        <List className={classes.list}>
            {disablePlatform !== true ? (
                <>
                    {(isLoggedIn === true) && (
                        <>
                            <ListItem className={classes.listItem}>
                                <CustomDropdown
                                    noLiPadding
                                    buttonText={<span>Node: {replaceCanMuleWithImage(currentNode)}</span>}
                                    buttonProps={{
                                        className: classes.navLink,
                                        color: "transparent",
                                    }}
                                    dropdownList={availableNodes.map((name, index) => (
                                        <Link
                                            key={index}
                                            className={classes.dropdownLink}
                                            onClick={(event) => currentNodeFunc(event, name)}
                                        >
                                            {replaceCanMuleWithImage(name)}
                                        </Link>
                                    ))}
                                />
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <CustomDropdown
                                    noLiPadding
                                    buttonText={
                                        <span style={{ color: currentContext.toLowerCase().includes("hardcore") ? "red" : "inherit" }}>
            {currentContext + " "}
                                            {currentContext.toLowerCase() === "softcore ladder" && (
                                                <>
                                                    <img style={{ width: '16px' }} src={hardcoreOff} alt="hardcoreOff" />
                                                    <img style={{ width: '16px' }} src={ladderOn} alt="ladderOn" />
                                                </>
                                            )}
                                            {currentContext.toLowerCase() === "softcore non-ladder" && (
                                                <>
                                                    <img style={{ width: '16px' }} src={hardcoreOff} alt="hardcoreOff" />
                                                    <img style={{ width: '16px' }} src={ladderOff} alt="ladderOff" />
                                                </>
                                            )}
                                            {currentContext.toLowerCase() === "hardcore ladder" && (
                                                <>
                                                    <img style={{ width: '16px' }} src={hardcoreOn} alt="hardcoreOn" />
                                                    <img style={{ width: '16px' }} src={ladderOn} alt="ladderOn" />
                                                </>
                                            )}
                                            {currentContext.toLowerCase() === "hardcore non-ladder" && (
                                                <>
                                                    <img style={{ width: '16px' }} src={hardcoreOn} alt="hardcoreOn" />
                                                    <img style={{ width: '16px' }} src={ladderOff} alt="ladderOff" />
                                                </>
                                            )}
        </span>
                                    }
                                    buttonProps={{
                                        className: classes.navLink,
                                        color: "transparent",
                                    }}
                                    dropdownList={[
                                        <Link className={classes.dropdownLink} onClick={(event) => currentContextFunc(event, "Softcore Ladder")}>
                                            Softcore Ladder <img style={{ width: '16px' }} src={hardcoreOff} alt="hardcoreOff" /><img style={{ width: '16px' }} src={ladderOn} alt="ladderOn" />
                                        </Link>,
                                        <Link className={classes.dropdownLink} onClick={(event) => currentContextFunc(event, "Softcore Non-Ladder")}>
                                            Softcore Non-Ladder <img style={{ width: '16px' }} src={hardcoreOff} alt="hardcoreOff" /><img style={{ width: '16px' }} src={ladderOff} alt="ladderOff" />
                                        </Link>,
                                        <Link style={{ color: "red" }} className={classes.dropdownLink} onClick={(event) => currentContextFunc(event, "Hardcore Ladder")}>
                                            Hardcore Ladder <img style={{ width: '16px' }} src={hardcoreOn} alt="hardcoreOn" /><img style={{ width: '16px' }} src={ladderOn} alt="ladderOn" />
                                        </Link>,
                                        <Link style={{ color: "red" }} className={classes.dropdownLink} onClick={(event) => currentContextFunc(event, "Hardcore Non-Ladder")}>
                                            Hardcore Non-Ladder <img style={{ width: '16px' }} src={hardcoreOn} alt="hardcoreOn" /><img style={{ width: '16px' }} src={ladderOff} alt="ladderOff" />
                                        </Link>,
                                    ]}
                                />
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <CustomDropdown
                                    noLiPadding
                                    buttonText="Search 🛒"
                                    buttonProps={{
                                        className: classes.navLink,
                                        color: "transparent",
                                    }}
                                    buttonIcon={Search}
                                    dropdownList={[
                                        <Link to="/items" className={classes.dropdownLink} style={{ color: "#385170" }}>
                                            Private Search 🛒
                                        </Link>,
                                        <Link to="/" style={{ color: "#ff9800" }} className={classes.dropdownLink}>
                                            Public Search 🛒
                                        </Link>,
                                    ]}
                                />
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <CustomDropdown
                                    noLiPadding
                                    buttonText={<span>Pages <img
                                        style={{width: '16px', verticalAlign: 'middle'}}
                                        src={chestImg}
                                        alt="chest"
                                    /></span>}
                                    // buttonText="Pages 🧰"
                                    buttonProps={{
                                        className: classes.navLink,
                                        color: "transparent",
                                    }}
                                    buttonIcon={Apps}
                                    dropdownList={[
                                        <Link to="/preset" className={classes.dropdownLink}>
                                            Preset Data
                                        </Link>,
                                        <Link to="/trades" className={classes.dropdownLink}>
                                            Trades/Actions & Droppers
                                        </Link>,
                                        <Link to="/supplier" className={classes.dropdownLink}>
                                            Mule Games & Settings <img
                                            style={{width: '16px', verticalAlign: 'middle'}}
                                            src={chestImg}
                                            alt="chest"
                                        />
                                        </Link>,
                                        <Link to="/sitems" className={classes.dropdownLink}>
                                            Supplied Items Overview
                                        </Link>,
                                        <Link to="/sales" className={classes.dropdownLink}>
                                            Sales History
                                        </Link>,
                                    ]}
                                />
                            </ListItem>
                            {(isLoggedIn === true && isNodeOwner) && (
                                <ListItem className={classes.listItem}>
                                    <CustomDropdown
                                        noLiPadding
                                        buttonText="Node Pages 👑"
                                        buttonProps={{
                                            className: classes.navLink,
                                            color: "transparent",
                                        }}
                                        buttonIcon={Apps}
                                        dropdownList={[
                                            <Link to="/node" className={classes.dropdownLink}>
                                                Node Settings/Actions 👑
                                            </Link>,
                                            <Link to="/users" className={classes.dropdownLink}>
                                                Node Users 👑
                                            </Link>,
                                        ]}
                                    />
                                </ListItem>
                            )}
                            {(isLoggedIn === true && isStaff) && (
                                <ListItem className={classes.listItem}>
                                    <CustomDropdown
                                        noLiPadding
                                        buttonText="Staff"
                                        buttonProps={{
                                            className: classes.navLink,
                                            color: "transparent",
                                        }}
                                        buttonIcon={Apps}
                                        dropdownList={[
                                            <Link to="/staff" className={classes.dropdownLink}>
                                                Node Overview
                                            </Link>,
                                        ]}
                                    />
                                </ListItem>
                            )}
                            {(isLoggedIn === true) && (
                                <ListItem className={classes.listItem}>
                                    <CustomDropdown
                                        noLiPadding
                                        buttonText={userData["navigation_text"]}
                                        buttonProps={{
                                            className: classes.navLink,
                                            color: "transparent",
                                        }}
                                        buttonIcon={Person}
                                        dropdownList={[
                                            <Link to="/due" className={classes.dropdownLink}>
                                                Profile
                                            </Link>,
                                            <Link to={""} className={classes.dropdownLink} onClick={logoutFunc}>Logout</Link>,
                                        ]}
                                    />
                                </ListItem>
                            )}
                        </>
                    )}
                </>
            ) : null}
            {(isLoggedIn === false && disablePlatform !== true) && (
                <>
                    <ListItem className={classes.listItem}>
                        <Link
                            to="/login"
                            className={classes.dropdownLink}
                            style={{
                                color: "#385170",
                                border: "2px solid #385170",
                                borderRadius: "5px",
                                padding: "10px 15px",
                                textDecoration: "none",
                                display: "inline-block",
                                fontWeight: "bold",
                                backgroundColor: "#ff9800", // Light grey background color
                                transition: "background-color 0.3s, color 0.3s",
                            }}
                            onMouseEnter={(e) => {
                                e.target.style.backgroundColor = "#385170";
                                e.target.style.color = "#ff9800";
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#ff9800";
                                e.target.style.color = "#385170";
                            }}
                        >
                            Login
                        </Link>
                    </ListItem>
                </>
            )}
        </List>
    );
}
